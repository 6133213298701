<template>
  <div :class="containerClass">
    <div class="text-center">
      <img v-if="image" :src="image" class="mx-auto mb-4 max-w-44" />
      <u-icon v-else :name="icon" class="text-heroblue/75" :class="fontSize.icon" />
      <h3 class="font-serif mb-1 break-long-string" :class="fontSize.h">{{heading}}</h3>
      <p class="mb-4 text-gray-500" :class="fontSize.p">{{description}}</p>
    </div>
    <slot />
  </div>
</template>

<script setup>
  const props = defineProps({
    heading: String,
    description: String,
    icon: String,
    size: String,
    image: String
  });

  const containerClass = computed(() => {
    if (props.size === 'xs') {
      return 'pt-2';
    } else {
      return 'w-full max-w-3xl mx-auto pt-8 sm:pt-16';
    }
  });

  const fontSize = computed(() => {
    if (props.size === 'xs') {
      return {
        icon: 'text-4xl mb-1',
        h: 'text-xl sm:text-2xl',
        p: 'text-sm sm:text-base',
      };
    } else {
      return {
        icon: 'text-6xl sm:text-8xl mb-2',
        h: 'text-3xl sm:text-4xl',
        p: 'text-base sm:text-xl'
      };
    }
  });
</script>
